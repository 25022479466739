@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
recruit
========================================================================== */
#recruit {

  // under
  .under-mv__title {

    @include g.mq(md) {
      width: 242px;
    }

    @include g.mq(sm) {
      width: f.px2vw(383);
      padding-bottom: f.px2vw(47);
    }
  }

  .recruit-wrap {

    &__news {
      position: relative;
      background-color: #fff3e8;
      overflow: hidden;

      @include g.mq(md) {
        padding: 50px 0;
      }
      @media screen and (min-width: 767px) and  (max-width: 1100px){
        padding: 50px f.px2vw(20,1100) ;
      }

      @include g.mq(sm) {
        padding: f.px2vw(100) f.px2vw(35) f.px2vw(66);
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include g.mq(md) {
          background-image: url("../img/recruit/recruit-bg01.png");
          background-size: cover;
          background-repeat: no-repeat;  
          width: 1284px;
          height: 341px;
          top: -45px;
        }
        @include g.mq(sm) {
          background-image: url("../img/recruit/sp/recruit-bg01.png");
          background-size: cover;
          background-repeat: no-repeat;  
          top: f.px2vw(-45);
          width: f.px2vw(748);
          height: f.px2vw(488);
        }
      }

      .news-box {
        position: relative;
        max-width: 1080px;
        margin: 0 auto;  

        &__title {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          background-color: g.$color-orange;
          color: #fff;

          @include g.mq(md) {
            width: 280px;
            height: 80px;
            border-radius: 40px;
            font-size: 24px;
            top: -40px;
            left: 0;
          }

          @include g.mq(sm) {
            width: f.px2vw(280);
            height: f.px2vw(80);
            border-radius: f.px2vw(40);
            font-size: f.px2vw(40);
            top: f.px2vw(-56);
            left: f.px2vw(-33);
          }
        }

        &__list {
          background-color: #fff;
          margin-left: auto;

          @include g.mq(md) {
            width: 76%;
            border-radius: 4px;
            padding: 40px 45px;
          }

          @include g.mq(sm) {
            width: 97.5%;
            border-radius: f.px2vw(4);
            padding: f.px2vw(57) f.px2vw(40) f.px2vw(55) f.px2vw(45);
          }

          .list-item{
            font-weight: 400;

            @include g.mq(md) {
              font-size: 18px;
              line-height: 2.2;
            }

            @include g.mq(sm) {
              font-size: f.px2vw(24);
              margin-top: f.px2vw(18);
              line-height: 1.3;
            }

            &:first-child {
              margin-top: 0;
            }
          }
        } 
      }
    }

    &__message {

      @include g.mq(md) {
        margin: 120px 0 140px;
      }

      @include g.mq(sm) {
        margin: f.px2vw(120) 0 f.px2vw(70);
      }

      .message-title {
        margin: 0 auto;

        @include g.mq(md) {
          width: 230px;
        }

        @include g.mq(sm) {
          width: f.px2vw(333);
        }
      }

      .message-txt {
        font-weight: bold;
        text-align: center;

        @include g.mq(md) {
          font-size: 24px;
          line-height: 2.3;
          margin-top: 38px;
        }

        @media screen and (min-width: 767px) and  (max-width: 1100px){
          padding: 0 f.px2vw(20,1100);
        }

        @include g.mq(sm) {
          font-size: f.px2vw(36);
          line-height: 2;
          margin-top: f.px2vw(52);
        }
      }

      .message-box {

        @include g.mq(md) {
          margin-top: 90px;
        }

        @include g.mq(sm) {
          margin-top: f.px2vw(90);
        }

        &__title {
          font-weight: bold;
          text-align: center;
          letter-spacing: 0.1em;

          @include g.mq(md) {
            font-size: 28px;
            line-height: 2;
          }

          @include g.mq(sm) {
            font-size: f.px2vw(40);
            line-height: 1.4;
          }
        }

        &__list {
          max-width: 1080px;
          margin: 0 auto;

          @include g.mq(md) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;  
            margin-top: 68px;
          }

          @media screen and (min-width: 767px) and  (max-width: 1100px){
            padding: 0 f.px2vw(20,1100);
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(83);
            padding: 0 f.px2vw(20) 0 f.px2vw(60);
          }

          .list-item {
            position: relative;

            @include g.mq(md) {
              width: 32.41%;
              box-shadow: 0 0 30px rgba(0,0,0,.1);
              padding: 110px 0 50px;
              border-radius: 4px;
            }

            @media screen and (min-width: 767px) and  (max-width: 1100px){
              box-shadow: 0 0 f.px2vw(30) rgba(0,0,0,.1);
              padding: f.px2vw(110,1100) 0 f.px2vw(50,1100);
            }

            @include g.mq(sm) {
              width: 89%;
              box-shadow: 0 0 f.px2vw(30) rgba(0,0,0,.1);
              padding: f.px2vw(50) 0;
              margin-top: f.px2vw(40);
              border-radius: f.px2vw(4);
            }
            
            &::before {
              content: "";
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              background-image: url("../img/recruit/recruit-line01.png");
              background-size: cover;
              background-repeat: no-repeat;

              @include g.mq(md) {
                bottom: 50px;
                width: 330px;
                height: 82px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                bottom: f.px2vw(50,1100);
                width: f.px2vw(330,1100);
                height: f.px2vw(82,1100);
              }

              @include g.mq(sm) {
                bottom: f.px2vw(58);
                width: f.px2vw(460);
                height: f.px2vw(122);
              }
            }

            &__txt {
              font-weight: bold;
              text-align: center;

              @include g.mq(md) {
                font-size: 22px;
                line-height: 1.81;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                font-size: f.px2vw(22,1100);
              }

              @include g.mq(sm) {
                font-size: f.px2vw(30);
                line-height: 2;
              }
            }

            &__number {
              position: absolute;

              @include g.mq(md) {
                left: 50%;
                transform: translateX(-50%);  
                top: -20px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                top: f.px2vw(-20,1100);
              }

              @include g.mq(sm) {
                top: 50%;
                transform: translateY(-50%);
                left: f.px2vw(-40);
              }
            }

            &__01 {
              @include g.mq(sm) {
                margin-top: 0;
              }
              .list-item__number {
                @include g.mq(md) {
                  width: 83px;
                }
                @media screen and (min-width: 767px) and  (max-width: 1100px){
                  width: f.px2vw(83,1100);
                }
                @include g.mq(sm) {
                  width: f.px2vw(83);
                }
              }
              .list-item__txt {
                color: #e35035;
              }
            }
            &__02 {
              @include g.mq(md) {
                margin-top: 40px;
              }
              @include g.mq(sm) {
                margin-left: auto;
                margin-right: f.px2vw(35);
              }
              .list-item__number {
                @include g.mq(md) {
                  width: 87px;
                }
                @media screen and (min-width: 767px) and  (max-width: 1100px){
                  width: f.px2vw(87,1100);
                }
                @include g.mq(sm) {
                  width: f.px2vw(87);
                }
              }
              .list-item__txt {
                color: #dca000;
              }
            }
            &__03 {
              @include g.mq(sm) {
                margin-left: auto;
              }
              .list-item__number {
                @include g.mq(md) {
                  width: 90px;
                }
                @media screen and (min-width: 767px) and  (max-width: 1100px){
                  width: f.px2vw(90,1100);
                }
                @include g.mq(sm) {
                  width: f.px2vw(90);
                }
              }
              .list-item__txt {
                color: #38a372;
              }
            }
          }
        }
      }
    }

    &__content {
      background-color: #fff3e8;

      @include g.mq(md) {
        padding: 120px 0 200px;
      }

      @media screen and (min-width: 767px) and  (max-width: 1100px){
        padding: 120px f.px2vw(20,1100) 200px;
      }

      @include g.mq(sm) {
        margin-top: 10px;
        padding: f.px2vw(110) f.px2vw(30) f.px2vw(300);
      }

      .content-box {
        position: relative;
        max-width: 1080px;
        margin: 0 auto;  
        background-color: #fff;

        @include g.mq(md) {
          padding: 77px 90px 80px;
          border-radius: 4px;
        }
        @include g.mq(sm) {
          padding: f.px2vw(66) f.px2vw(40) f.px2vw(88);
          border-radius: f.px2vw(4);
        }

        &__icon {
          position: absolute;

          &.icon-01 {

            @include g.mq(md) {
              top: -64px;
              left: -42px;
              width: 216px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-64);
              left: f.px2vw(8);
              width: f.px2vw(216);
            }
          }

          &.icon-02 {

            @include g.mq(md) {
              width: 182px;
              top: -26px;
              right: 16px;
            }
            @include g.mq(sm) {
              top: f.px2vw(-47);
              right: f.px2vw(3);
              width: f.px2vw(182);
            }
          }
        }

        &__title {
          font-weight: bold;
          text-align: center;
          letter-spacing: 0.1em;

          @include g.mq(md) {
            font-size: 24px;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(36);
          }
        }

        &__list {
          width: 100%;

          @include g.mq(md) {
            margin-top: 62px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(40);
          }

          .item-dl {
            width: 100%;
            position: relative;

            @include g.mq(md) {
              display: flex;
            }

            @include g.mq(sm) {
              border-top: 1px solid #cdcdcd;
              padding: f.px2vw(40) f.px2vw(30) f.px2vw(40) f.px2vw(18);
            }
            &::before {
              content: "";
              position: absolute;
              background-color: g.$color-orange;

              @include g.mq(sm) {
                top: -1px;
                left: 0;
                width: f.px2vw(200);
                height: 1px;
              }
            }

            .dt-item {
              font-weight: 500;
  
              @include g.mq(md) {
                border-top: 1px solid g.$color-orange;
                font-size: 14px;
                text-align: center;
                padding: 31px 0;
                flex: 0 0 200px;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(28);
                line-height: 1.07;
                width: 100%;
              }
            }
  
            .dd-item {
              font-weight: 400;
  
              @include g.mq(md) {
                border-top: 1px solid #cdcdcd;
                font-size: 16px;
                line-height: 2;
                padding: 21px 0 21px 78px;
                flex: 0 1 100%;
              }
              @include g.mq(sm) {
                font-size: f.px2vw(26);
                line-height: 1.38;
                width: 100%;
                margin-top: f.px2vw(30);
              }

              .point {
                position: relative;
                display: block;
                font-weight: 400;
                text-decoration: underline solid #fffd30;

                @include g.mq(md) {
                  font-size: 16px;
                  text-decoration-thickness: 6px;
                  text-underline-offset: -1px;  
                  padding-left: 68px;
                }

                @include g.mq(sm) {
                  text-decoration-thickness: f.px2vw(6);
                  text-underline-offset: f.px2vw(-1);  
                  padding: f.px2vw(19) 0 f.px2vw(37) f.px2vw(128);
                }

                &::before {
                  content: "";
                  position: absolute;
                  background-image: url("../img/recruit/recruit-icon06.png");
                  background-size: cover;
                  background-repeat: no-repeat;
                  
                  @include g.mq(md) {
                    top: 3px;
                    left: 3px;
                    width: 57px;
                    height: 24px;
                  }
                  @include g.mq(sm) {
                    top: f.px2vw(16);
                    left: 0;
                    width: f.px2vw(114);
                    height: f.px2vw(48);
                  }
                }
              }

              .small {

                @include g.mq(md) {
                  font-size: 12px;
                  padding-left: 15px;
                }
                @include g.mq(sm) {
                  font-size: f.px2vw(24);
                }
              }
            }

            &:last-child {
              @include g.mq(sm) {
                border-bottom: 1px solid #cdcdcd;
              }
              &::after {
                content: "";
                position: absolute;
                background-color: g.$color-orange;
  
                @include g.mq(sm) {
                  bottom: -1px;
                  left: 0;
                  width: f.px2vw(200);
                  height: 1px;
                }
              }
              .dt-item {
                @include g.mq(md) {
                  border-bottom: 1px solid g.$color-orange;
                }
              }
    
              .dd-item {
                @include g.mq(md) {
                  border-bottom: 1px solid #cdcdcd;
                }
              }
            }
          }
        }

        &__txt {
          font-weight: 400;
          text-align: right;

          @include g.mq(md) {
            font-size: 14px;
            padding-top: 27px;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(22);
            padding-top: f.px2vw(37);
          }
        }

        &:last-child {
          @include g.mq(md) {
            margin-top: 62px;
          }
          @include g.mq(sm) {
            margin-top: f.px2vw(100);
          }
        }
      }
    }
  }
}