@use "../global" as g;
@use 'function' as f;
/*==============================================================
# 共通
==============================================================*/
.anker-point {
  opacity: 0;
}

/*==============================================================
# under
==============================================================*/
.under-mv {
  position: relative;
  background-image: url("../img/common/under-bg01.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;

  @include g.mq(md) {
    height: 360px;
  }

  @include g.mq(sm) {
    height: f.px2vw(500);
  }

  &::before {
    content: "";
    position: absolute;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;

    @include g.mq(md) {
      width: 2px;
      height: 100px;
    }

    @include g.mq(sm) {
      width: f.px2vw(2);
      height: f.px2vw(100);
    }
  }

  &__title {
    margin: 0 auto;

    @include g.mq(md) {
      padding-top: 80px;
    }

    @include g.mq(sm) {
      padding-top: f.px2vw(105);
    }
  }

  .sub-title {
    font-weight: bold;
    text-align: center;
    color: #fff;

    @include g.mq(md) {
      font-size: 22px;
      padding-top: 25px;
    }

    @include g.mq(sm) {
      font-size: f.px2vw(40);
    }
  }
}

/*==============================================================
# fadeIn
==============================================================*/
.fadeIn_up {
  opacity: 0;
  transform: translate(0, 50px);
  transition: 1s;
}
.fadeIn_up.is-show {
  transform: translate(0, 0);
  opacity: 1;
}
.fadeIn_up_repeat {
  opacity: 0;
  transform: translate(0, 50px);
  transition: 1s;
}
.fadeIn_up_repeat.is-show {
  transform: translate(0, 0);
  opacity: 1;
}
