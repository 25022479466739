@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
about
========================================================================== */
#about {

  /*# work
  ==============================================================*/
  .work {

    // under
    .under-mv__title {
      
      @include g.mq(md) {
        width: 169px;
      }

      @include g.mq(sm) {
        width: f.px2vw(269);
        padding-bottom: f.px2vw(57);
      }
    }

    .work-wrap {

      @include g.mq(md) {
        margin: 120px 0;
      }

      @include g.mq(sm) {
        margin: f.px2vw(103) 0 f.px2vw(183);
      }

      &__about {
        position: relative;
        max-width: 1310px;
        margin-left: auto;

        @media screen and (min-width: 1501px) {
          max-width: 1500px;
          margin: 0 auto;
        }

        @include g.mq(md) {
          overflow: hidden;
        }

        .about-message {
          width: 100%;
          margin: 0 auto;

          @media screen and (min-width: 1501px) {
            max-width: 1080px;
            margin: 0 auto;
            padding-left: 0 !important;
          }

          @include g.mq(md) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            padding-top: 87px;
            padding-left: 20px;
          }

          @include g.mq(sm) {
            padding: 0 f.px2vw(40);
          }

          .main-txt {
            font-weight: bold;
            letter-spacing: 0.1em;
            line-height: 2;

            @include g.mq(md) {
              font-size: 28px;
            }

            @include g.mq(sm) {
              font-size: f.px2vw(36);
            }
          }

          .sub-txt {
            font-weight: 500;

            @include g.mq(md) {
              font-size: 18px;
              line-height: 2.2;
              padding-top: 53px;
            }

            @include g.mq(sm) {
              font-size: f.px2vw(28);
              line-height: 2;
              padding-top: f.px2vw(27);
            }
          }
        }

        .about-bg {
          
          @include g.mq(md) {
            position: relative;
            margin-left: auto;
            z-index: -1;
            width: 1290px;
            margin-left: auto;
          }

          // @media screen and (min-width: 767px) and  (max-width: 1100px){
          //   width: f.px2vw(1050,1100);
          // }

          @include g.mq(sm) {
            margin-top: f.px2vw(50);
          }

          img {
            display: block;

            @include g.mq(md) {
              width: 1050px;
              margin-left: auto;
            }
          }
        }
      }

      &__list {
        max-width: 1080px;
        margin: 0 auto;

        @include g.mq(md) {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;  
          margin-top: 117px;
        }

        @media screen and (min-width: 767px) and  (max-width: 1100px){
          padding: 0 f.px2vw(20,1100);
        }

        @include g.mq(sm) {
          margin-top: f.px2vw(90);
          padding: 0 f.px2vw(30);
        }

        .item {
          position: relative;

          @include g.mq(md) {
            width: 46.3%;
            box-shadow: 0 0 30px rgba(0,0,0,.1);
            margin-top: 60px;
            border-radius: 4px;
            padding: 0 30px 28px;
          }

          @include g.mq(sm) {
            width: 100%;
            box-shadow: 0 0 f.px2vw(30) rgba(0,0,0,.1);
            border-radius: f.px2vw(4);
            padding: 0 f.px2vw(30) f.px2vw(30);
            margin-top: f.px2vw(93);
          }

          .item-title {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            background-color: g.$color-orange;
            font-weight: bold;
            color: #fff;

            @include g.mq(md) {
              top: -30px;
              height: 60px;
              padding: 0 55px;
              border-radius: 30px;
              font-size: 24px;
            }

            @include g.mq(sm) {
              top: f.px2vw(-30);
              font-size: f.px2vw(30);
              height: f.px2vw(60);
              padding: 0 f.px2vw(35);
              border-radius: f.px2vw(30);
            }
          }

          .item-img {
            margin: 0 auto;
          }

          .item-txt {
            font-weight: 400;
            background-color: #f1f1f1;
            line-height: 2;

            @include g.mq(md) {
              height: 120px;
              border-radius: 4px;
              font-size: 14px;
              margin-top: 17px;
              padding: 15px 20px;
            }

            @include g.mq(sm) {
              font-size: f.px2vw(28);
              border-radius: f.px2vw(4);
              padding: f.px2vw(13) f.px2vw(31) f.px2vw(24);
              margin-top: f.px2vw(15);
            }
          }

          &:first-child {
            margin-top: 0;
          }

          &.item-01 {
            .item-img {
              @include g.mq(md) {
                width: 90%;
                margin-top: 31px;
              }

              @include g.mq(sm) {
                width: 63%;
                padding-top: f.px2vw(30);
              }
            }
          }

          &.item-02 {
            @include g.mq(md) {
              margin-top: 75px;
            }
            .item-img {
              @include g.mq(md) {
                width: 90%;
                margin-top: 52px;
              }
              @include g.mq(sm) {
                width: 63%;
                padding-top: f.px2vw(50);
              }
            }
          }
          &.item-03 {
            @include g.mq(md) {
              margin-top: 33px;
            }
            .item-img {
              @include g.mq(md) {
                width: 80%;
                margin-top: 38px;
              }
              @include g.mq(sm) {
                width: 55%;
                padding-top: f.px2vw(50);
              }
            }
          }
          &.item-04 {
            @include g.mq(md) {
              margin-top: 100px;
            }
            
            .item-img {
              @include g.mq(md) {
                padding-top: 55px;
              }
              @include g.mq(sm) {
                width: 70%;
                padding-top: f.px2vw(60);
              }
            }
          }
        }
      }
    }
  }

  /*# workplace
  ==============================================================*/
  .workplace {
    .under-mv__title {
      
      @include g.mq(md) {
        width: 345px;
        margin-top: 70px;
      }

      @include g.mq(sm) {
        width: f.px2vw(554);
        padding: f.px2vw(118) 0 f.px2vw(22);
      }
    }

    .workplace-wrap {

      @include g.mq(md) {
        margin-top: 100px;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(100);
      }

      &__title {
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.1em;
        line-height: 2;

        @include g.mq(md) {
          font-size: 36px;
        }

        @include g.mq(sm) {
          font-size: f.px2vw(36);
        }
      }

      &__txt {
        font-weight: 500;
        text-align: center;

        @include g.mq(md) {
          font-size: 18px;
          line-height: 2.2;
          margin-top: 30px;
        }

        @media screen and (min-width: 767px) and  (max-width: 1100px){
          padding: 0 f.px2vw(20,1100);
        }

        @include g.mq(sm) {
          font-size: f.px2vw(28);
          line-height: 2;
          margin-top: f.px2vw(26);
        }
      }

      &__img {

        @include g.mq(md) {
          margin-top: 110px;
        }

        @include g.mq(sm) {
          margin-top: f.px2vw(110);
        }
      }

      &__grid {
        background-color: #e65136;

        @include g.mq(md) {
          padding: 120px 0 96px;
        }

        @media screen and (min-width: 767px) and  (max-width: 1100px){
          padding: 120px f.px2vw(20,1100) 96px;
        }

        @include g.mq(sm) {
          padding: f.px2vw(120) f.px2vw(30) f.px2vw(82);
        }

        .grid-box {
      
          @include g.mq(md) {
            max-width: 1080px;
            margin: 0 auto;
            display: grid;  
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: 335px;
            column-gap: 15px;
            row-gap: 15px;
          }

          @media screen and (min-width: 767px) and  (max-width: 1100px){
            grid-auto-rows: f.px2vw(335,1100);
            column-gap: f.px2vw(15,1100);
            row-gap: f.px2vw(15,1100);
          }

          .grid-item {
            background-color: #fff;

            @include g.mq(md) {
              border-radius: 4px;
            }

            @media screen and (min-width: 767px) and  (max-width: 1100px){
              border-radius: f.px2vw(4,1100);
            }

            @include g.mq(sm) {
              display: flex;
              align-items: center;
              justify-content: space-between;  
              margin-top: f.px2vw(20);
              border-radius: f.px2vw(4);
            }

            &__message  {

              @include g.mq(sm) {
                flex: 0 1 100%;
              }

              .message-item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include g.mq(md) {
                  margin-top: 10px;
                }

                @media screen and (min-width: 767px) and  (max-width: 1100px){
                  margin-top: f.px2vw(10,1100);
                }

                &:first-child {
                  margin-top: 0;
                }
              }
            }

            &__txt {
              font-weight: bold;
              text-align: center;

              @include g.mq(md) {
                font-size: 24px;
                line-height: 1.3;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                font-size: f.px2vw(24,1100);
              }

              @include g.mq(sm) {
                font-size: f.px2vw(32);
              }

              span {
                display: block;

                @include g.mq(md) {
                  font-size: 16px;
                  margin-top: 10px;
                }

                @media screen and (min-width: 767px) and  (max-width: 1100px){
                  font-size: f.px2vw(16,1100);
                  margin-top: f.px2vw(10,1100);
                }

                @include g.mq(sm) {
                  font-size: f.px2vw(22);
                  margin-top: f.px2vw(15);
                }
              }
            }

            &__box {
              display: flex;
              align-items: center;

              .box-number {
                font-weight: bold;
                color: #e65136;

                @include g.mq(md) {
                  font-size: 100px;
                  margin-right: 20px;
                }

                @media screen and (min-width: 767px) and  (max-width: 1100px){
                  font-size: f.px2vw(100,1100);
                  margin-right: f.px2vw(20,1100);
                }

                @include g.mq(sm) {
                  font-size: f.px2vw(100);
                  margin-right: f.px2vw(13);
                }

                span {

                  @include g.mq(md) {
                    font-size: 60px;
                  }

                  @media screen and (min-width: 767px) and  (max-width: 1100px){
                    font-size: f.px2vw(60,1100);
                  }

                  @include g.mq(sm) {
                    font-size: f.px2vw(60);
                  }
                }
              } 

              .box-txt {
                font-weight: bold;

                @include g.mq(md) {
                  font-size: 24px;
                }

                @media screen and (min-width: 767px) and  (max-width: 1100px){
                  font-size: f.px2vw(24,1100);
                }

                @include g.mq(sm) {
                  font-size: f.px2vw(32);
                }
              }
            }

            &__img {

              @include g.mq(md) {
                margin: 0 auto;
              }
            }
          }

          .item-01 {
            background-color: #003855;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @include g.mq(md) {
              grid-column: 1/2;
              grid-row: 1/2;  
            }

            @include g.mq(sm) {
              padding: f.px2vw(65) 0;
              margin-top: 0;
            }

            .grid-item__img {
  
              @include g.mq(md) {
                width: 121px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                width: f.px2vw(121,1100);
              }

              @include g.mq(sm) {
                width: f.px2vw(182);
              }
            }
  
            .grid-item__txt {
              font-weight: bold;
              text-align: center;
              color: #fff;
  
              @include g.mq(md) {
                font-size: 22px;
                line-height: 1.136;
                margin-top: 45px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                font-size: f.px2vw(22);
                margin-top: f.px2vw(45,1100);
              }

              @include g.mq(sm) {
                font-size: f.px2vw(36);
                margin-top: f.px2vw(28);
              }
            }
          }
  
          .item-02 {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include g.mq(md) {
              grid-column: 2/4;
              grid-row: 1/2;  
              padding:0 30px 0 50px;
            }

            @media screen and (min-width: 767px) and  (max-width: 1100px){
              padding: 0 f.px2vw(30,1100) 0 f.px2vw(50,1100);
            }

            @include g.mq(sm) {
              padding:f.px2vw(37) f.px2vw(40) f.px2vw(44);
            }

            .grid-item__img {
  
              @include g.mq(md) {
                flex: 0 0 265px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                flex: 0 0 f.px2vw(265,1100);
              }

              @include g.mq(sm) {
                flex: 0 0 f.px2vw(200);
                margin-right: f.px2vw(45);
              }
            }
            .grid-item__message {
  
              @include g.mq(md) {
                margin-left: 35px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                margin-left: f.px2vw(35);
              }
            }
          }

          .item-03 {
            grid-column: 1/2;
            grid-row: 2/3;

            @include g.mq(md) {
              padding: 25px 20px 0;
            }

            @media screen and (min-width: 767px) and  (max-width: 1100px){
              padding: f.px2vw(25,1100) f.px2vw(20,1100) 0;
            }

            @include g.mq(sm) {
              padding: f.px2vw(31) f.px2vw(40) f.px2vw(30);
            }

            .grid-item__img {
  
              @include g.mq(md) {
                width: 142px;
                margin-bottom: 20px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                width: f.px2vw(142,1100);
                margin-bottom: f.px2vw(20,1100);
              }

              @include g.mq(sm) {
                flex: 0 0 f.px2vw(112);
                margin: 0 f.px2vw(48) 0 f.px2vw(50);
              }
            }
          }

          .item-04 {
            grid-column: 2/3;
            grid-row: 2/3;

            @include g.mq(md) {
              padding: 33px 20px 0;
            }

            @media screen and (min-width: 767px) and  (max-width: 1100px){
              padding: f.px2vw(33.1100) f.px2vw(20,1100) 0;
            }

            @include g.mq(sm) {
              padding: f.px2vw(38) f.px2vw(40) f.px2vw(34);
            }

            .grid-item__img {
  
              @include g.mq(md) {
                width: 179px;
                margin-bottom: 30px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                width: f.px2vw(179,1100);
                margin-bottom: f.px2vw(30,1100);
              }

              @include g.mq(sm) {
                flex: 0 0 f.px2vw(146);
                margin: 0 f.px2vw(48) 0 f.px2vw(30);
              }
            }
          }

          .item-05 {

            @include g.mq(md) {
              padding: 21px 20px 0;
              grid-column: 3/4;
              grid-row: 2/3;  
            }

            @media screen and (min-width: 767px) and  (max-width: 1100px){
              padding: f.px2vw(21,1100) f.px2vw(20,1100) 0;
            }

            @include g.mq(sm) {
              padding:f.px2vw(37) f.px2vw(40) f.px2vw(44);
            }

            .grid-item__img {
  
              @include g.mq(md) {
                width: 247px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                width: f.px2vw(247,1100);
              }

              @include g.mq(sm) {
                flex: 0 0 f.px2vw(233);
                margin-right: f.px2vw(45);
              }
            }

            .grid-item__message {

              @include g.mq(md) {
                display: flex;
                justify-content: center;
                align-items: baseline;
                max-width: 220px;
                margin: 0 auto ;  
              }

              .message-item {

                @include g.mq(md) {
                  display: block;
                  margin-left: 60px;
                }

                @media screen and (min-width: 767px) and  (max-width: 1100px){
                  margin-left: f.px2vw(60,1100);
                }

                .grid-item__box {
                  position: relative;

                  .box-number {
  
                    @include g.mq(md) {
                      font-size: 64px;
                      margin-right: 0;
                    }

                    @media screen and (min-width: 767px) and  (max-width: 1100px){
                      font-size: f.px2vw(64,1100);
                    }
                  }
  
                  .box-txt {
  
                    @include g.mq(md) {
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);  
                      right: -40px;
                    }

                    @media screen and (min-width: 767px) and  (max-width: 1100px){
                      right: f.px2vw(-40,1100);
                    }
                  }
                }

                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }
        }
        

        .grid-txt {
          color: #fff;
          font-weight: 400;
          text-align: right;

          @include g.mq(md) {
            max-width: 1080px;
            font-size: 14px;
            margin: 10px auto 0;
          }

          @media screen and (min-width: 767px) and  (max-width: 1100px){
            font-size: f.px2vw(14,1100);
            margin: f.px2vw(10,1100) auto 0;
          }

          @include g.mq(sm) {
            font-size: f.px2vw(22);
            margin-top: f.px2vw(15);
          }
        }
      }
    }
  }

  /*# faq
  ==============================================================*/
  .faq {

    @include g.mq(md) {
      margin: 115px 0 220px;
    }

    @media screen and (min-width: 767px) and  (max-width: 1100px){
      padding: 0 f.px2vw(20,1100);
    }

    @include g.mq(sm) {
      margin: f.px2vw(125) 0 f.px2vw(310);
    }

    .faq-wrap {

      &__title {
        margin: 0 auto;
        
        @include g.mq(md) {
          width: 143px;
        }

        @include g.mq(sm) {
          width: f.px2vw(209);
        }
      }

      &__list {
        max-width: 1080px;
        margin: 0 auto;

        @include g.mq(md) {
          margin-top: 60px;
        }

        @include g.mq(sm) {
          padding: 0 f.px2vw(20);
          margin-top: f.px2vw(115);
        }

        .list-item {

          @include g.mq(md) {
            margin-top: 60px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(56);
          }

          &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            cursor: pointer;

            @include g.mq(md) {
              width: 83.3%;
              height: 80px;
              border-radius: 60px;
              padding: 32px 45px 32px 57px;
            }

            @include g.mq(sm) {
              width: 98.5%;
              border-radius: f.px2vw(40);
              padding: f.px2vw(16) f.px2vw(33) f.px2vw(16) f.px2vw(32);
            }

            &::before {
              position: absolute;
              content: "";
              bottom: 0;
              left: 0;
              
              @include g.mq(md) {
                width: 26px;
                height: 33px;
              }

              @include g.mq(sm) {
                width: f.px2vw(26);
                height: f.px2vw(33);
              }
            }

            .head-txt {
              position: relative;
              color: #000;
              font-weight: 500;

              @include g.mq(md) {
                padding-left: 60px;
                font-size: 16px;
              }

              @include g.mq(sm) {
                padding-left: f.px2vw(60);
                font-size: f.px2vw(28);
                line-height: 1.71;
              }

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                background-image: url("../img/about/about-txt05.png");
                background-size: cover;
                background-repeat: no-repeat;

                @include g.mq(md) {
                  width: 28px;
                  height: 40px;
                }

                @include g.mq(sm) {
                  width: f.px2vw(33);
                  height: f.px2vw(47);
                }
              }
            }

            .icon {
              position: relative;
              display: inline-block;

              @include g.mq(md) {
                flex: 0 0 20px;
              }

              @include g.mq(sm) {
                flex: 0 0 f.px2vw(40);
              }

              &:before, &:after {
                position: absolute;
                content: "";
                display: block;
                transition: all .4s;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-color: #fff;
  
                @include g.mq(md) {
                  width: 28px;
                  height: 4px;  
                }
  
                @include g.mq(sm) {
                  width: f.px2vw(28);
                  height: f.px2vw(4);
                }
              }
  
              &:before {
                transform: translate(-50%, -50%) rotate(90deg);
              }
  
              &.open {
                &::before {
                  transform: translate(-50%, -50%) rotate(0deg);
                }
              }
            }
          }

          &__body {
            position: relative;
            border: 2px solid g.$color-orange;
            background-color: #fff;

            @include g.mq(md) {
              width: 83.3%;
              border-radius: 60px;
              margin-top: 30px;
              margin-left: auto;
              padding: 41px 58px;
            }

            @include g.mq(sm) {
              width: 98.5%;
              margin-top: f.px2vw(30);
              margin-left: auto;
              border-radius: f.px2vw(60);
              padding: f.px2vw(40);
            }

            &::before {
              content: "";
              position: absolute;
              background-image: url("../img/about/about-icon04.png");
              background-size: cover;
              background-repeat: no-repeat;
              
              @include g.mq(md) {
                bottom: 5px;
                right: 5px;
                width: 24px;
                height: 32px;
              }
              @include g.mq(sm) {
                bottom: f.px2vw(7);
                right: f.px2vw(7);
                width: f.px2vw(24);
                height: f.px2vw(32);
              }
            }

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              right: 0;
              background-image: url("../img/about/about-icon03.png");
              background-size: cover;
              background-repeat: no-repeat;
              z-index: -1;
              
              @include g.mq(md) {
                width: 26px;
                height: 33px;
              }

              @include g.mq(sm) {
                width: f.px2vw(26);
                height: f.px2vw(33);
              }
            }

            .body-txt {
              font-weight: 400;

              @include g.mq(md) {
                font-size: 14px;
                line-height: 2.5;
              }

              @include g.mq(sm) {
                font-size: f.px2vw(26);
                line-height: 1.85;
              }
            }
          }

          &__01 {
            .list-item__head {
              background-color: #8ad4af;

              &::before {
                background-image: url("../img/about/about-icon01.png");
                background-repeat: no-repeat;
                background-size: cover;    
              }
            }
          }
          &__02 {
            .list-item__head {
              background-color: #fdce29;

              &::before {
                background-image: url("../img/about/about-icon02.png");
                background-repeat: no-repeat;
                background-size: cover;    
              }
            }
          }

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}