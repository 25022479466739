/* ==========================================================================
    variables
   ========================================================================== */

// font
$font-sans: 'Noto Sans JP', sans-serif;

// color
$color-black: #000;
$color-yellow: #FFBA00;
$color-cyan: #60BBD6;
$color-purple: #EB9EDA;
$color-blue: #8CACBA;
$color-green: #81C768;
$color-red: #cf0f31;
$color-orange: #ff7800;

$font-color: #333333;


