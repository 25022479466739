@use "../global" as g;

/* ==========================================================================
    base
========================================================================== */

html {
  font-size: 62.5%;

  &.fixed {
    overflow-y:hidden;
  }
}

body {
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
  color: g.$font-color;
  background-color: #fff;
  font-family: g.$font-sans;
  font-weight: 500;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;

  @include g.mq(md) {
    font-size: 1.6rem;
  };

  &.fixed {
    overflow-y:hidden;
  }
}

a,
button {
  color: inherit;
  cursor: pointer;
}

img {
  max-width: 100%;
  width: 100%;
}

em {
  font-style: normal;
}

button {
  outline: none;
  border: 0;
  background-color: transparent;
}