@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
header
========================================================================== */
header {
  background-color: #fff;
  
  @include g.mq(md) {
    height: 80px;
  }

  @include g.mq(sm) {
    height: f.px2vw(100);
  }
  
  .header-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    
    &__logo {
      
      @include g.mq(md) {
        width: 260px;
      }

      @include g.mq(sm) {
        width: f.px2vw(416);
      }
    }

    &__menu {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("../img/common/menu-icon01.png");
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 50%;
      z-index: 999;
      cursor: pointer;
      transition: all .3s;

      @include g.mq(md) {
        top: 20px;
        right: 20px;
        width: 150px;
        height: 150px;
      }

      @include g.mq(sm) {
        top: f.px2vw(20);
        right: f.px2vw(10);
        width: f.px2vw(150);
        height: f.px2vw(150);
      }

      p {
        position: relative;
        font-weight: bold;
        color: #fff;

        @include g.mq(md) {
          font-size: 16px;
          padding-top: 25px;
        }

        @include g.mq(sm) {
          font-size: f.px2vw(20);
          padding-top: f.px2vw(35);
        }

        &::before,&::after {
          content: "";
          position: absolute;
          background-color: #fff;
          transition: all .3s;
          left: 50%;
          transform: translateX(-50%);
          height: 1px;  

          @include g.mq(md) {
            width: 50px;
          }

          @include g.mq(sm) {
            width: f.px2vw(66);
          }
        }
        &::before {

          @include g.mq(md) {
            top: 0;
          }

          @include g.mq(sm) {
            top: f.px2vw(3);
          }
        }
        &::after {

          @include g.mq(md) {
            top: 15px;
          }

          @include g.mq(sm) {
            top: f.px2vw(20);
          }
        }
      }

      &.active {
        border: none;
        p {
          &::before,&::after {
            left: 0;

            @include g.mq(md) {
              top: 10px;
            }

            @include g.mq(sm) {
              top: f.px2vw(10);
            }
          }
          &::before {
            transform: rotate(-30deg);
          }
          &::after {
            transform: rotate(30deg);
          }
        }
      }
    }
  }

  .header-menu {
    display: none;
    position: fixed;
    top: 0;
    z-index: 998;
    background-image: url("../img/common/menu-bg01.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;

    .menu-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .menu-list {

        &__item {
          text-align: center;
  
          @include g.mq(md) {
            margin-top: 78px;
          }
  
          @include g.mq(sm) {
            margin-top: f.px2vw(78);
          }
  
          .item-link {
            display: inline-block;
            font-weight: 500;
            letter-spacing: 0.1em;
            color: #fff;
  
            @include g.mq(md) {
              font-size: 32px;
            }
  
            @include g.mq(sm) {
              font-size: f.px2vw(40);
            }
          }
          
          &.small {
  
            @include g.mq(md) {
              margin-top: 110px;
            }
  
            @include g.mq(sm) {
              margin-top: f.px2vw(120);
            }
  
            .item-link {
              
              @include g.mq(md) {
                font-size: 18px;
              }
  
              @include g.mq(sm) {
                font-size: f.px2vw(28);
              }
            }
  
            &+.small {
  
              @include g.mq(md) {
                margin-top: 56px;
              }
  
              @include g.mq(sm) {
                margin-top: f.px2vw(77);
              }
            }
          }
  
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

  }
}
