@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
main
========================================================================== */
article {

  @include g.mq(md) {
    margin-bottom: 185px;
  }

  @include g.mq(sm) {
    margin-bottom: f.px2vw(297);
  }
}
/*# mv
==============================================================*/
.mv { 
  .mv-wrap {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    width: 100%;

    @include g.mq(md) {
      background-image: url("../img/top/mv-bg01.png");
      height: 720px;
    }

    @media screen and (min-width: 767px) and  (max-width: 1100px){
      height: f.px2vw(720,1100);
    }

    @include g.mq(sm) {
      background-image: url("../img/top/sp/mv-bg01.png");
      height: f.px2vw(700);
    }

    &__title {
      mix-blend-mode: overlay;
      margin: 0 auto;

      @include g.mq(md) {
        width: 927px;
        padding-top: 205px;
      }

      @media screen and (min-width: 767px) and  (max-width: 1100px){
        width: f.px2vw(927,1100);
        padding-top: f.px2vw(205,1100);
      }

      @include g.mq(sm) {
        width: f.px2vw(705);
        padding-top: f.px2vw(180);
      }
    }

    &__txt {
      font-weight: bold;
      text-align: center;
      color: #fff;
      letter-spacing: 0.1em;

      @include g.mq(md) {
        font-size: 22px;
        padding-top: 45px;
      }

      @media screen and (min-width: 767px) and  (max-width: 1100px){
        font-size: f.px2vw(22,1100);
        padding-top: f.px2vw(45,1100);
      }

      @include g.mq(sm) {
        font-size: f.px2vw(36);
        line-height: 1.6;
        padding-top: f.px2vw(30);
      }
    }
  }
}

/*# about
==============================================================*/
.about {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;

    @include g.mq(md) {
      background-image: url("../img/top/about-bg01.png");
      height: 763px;
    }

    @include g.mq(sm) {
      background-image: url("../img/top/sp/about-bg01.png");
      height: f.px2vw(1005);
    }
  }

  @include g.mq(md) {
    padding: 90px 0 150px;
  }

  @include g.mq(sm) {
    padding: f.px2vw(165) 0 f.px2vw(183);
  }

  .about-wrap {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;

      @include g.mq(md) {
        background-image: url("../img/top/about-gif01.gif");
        top: 160px;
        left: 40px;
        width: 370px;
        height: 660px;
      }

      @media screen and (min-width: 767px) and  (max-width: 1100px){
        top: f.px2vw(160,1100);
        left: f.px2vw(40,1100);
        width: f.px2vw(370,1100);
        height: f.px2vw(660,1100);
      }

      @include g.mq(sm) {
        background-image: url("../img/top/sp/about-bg02.png");
        width: f.px2vw(709);
        height: f.px2vw(195);
        top: f.px2vw(-45);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &::after {
      content: "";
      position: absolute;
      background-image: url("../img/top/about-gif02.gif");
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;

      @include g.mq(md) {
        top: 100px;
        right: 30px;
        width: 430px;
        height: 680px;
      }

      @media screen and (min-width: 767px) and  (max-width: 1100px){
        top: f.px2vw(100,1100);
        right: f.px2vw(30,1100);
        width: f.px2vw(430,1100);
        height: f.px2vw(680,1100);
      }
    }

    &__title {
      margin: 0 auto;

      @include g.mq(md) {
        width: 151px;
      }

      @include g.mq(sm) {
        width: f.px2vw(228);
      }
    }

    &__subtitle {
      position: relative;
      font-weight: bold;
      text-align: center;
      text-decoration: underline solid #fffd30;
      letter-spacing: 0.1em;

      @include g.mq(md) {
        font-size: 36px;
        line-height: 2;
        margin-top: 25px;
        text-decoration-thickness: 10px;
        text-underline-offset: -3px;  
      }

      @include g.mq(sm) {
        font-size: f.px2vw(48);
        line-height: 2.08;
        margin-top: f.px2vw(82);
        text-decoration-thickness: f.px2vw(20);
        text-underline-offset: f.px2vw(-3);  
        letter-spacing: 0.1em;
      }
    }

    &__txt {
      font-weight: 500;
      line-height: 2;
      text-align: center;
      letter-spacing: 0;

      @include g.mq(md) {
        font-size: 24px;
        margin-top: 50px;
      }

      @include g.mq(sm) {
        font-size: f.px2vw(32);
        margin-top: f.px2vw(65);
      }
    }

    &__list {
      max-width: 1080px;
      margin: 0 auto;
      position: relative;

      @include g.mq(md) {
        display: flex;
        justify-content: space-between;  
        margin-top: 165px;
      }

      @media screen and (min-width: 767px) and  (max-width: 1100px){
        padding: 0 f.px2vw(20,1100);
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(165);
        padding: 0 f.px2vw(30);
      }

      .list-item {
        position: relative;

        @include g.mq(md) {
          width: 42.59%;
        }

        &__img {

          @include g.mq(sm) {
            width: 63.3%;
          }
        }

        &__button {
          position: absolute;

          @include g.mq(md) {
            bottom: 75px;
            right: -41px;
          }

          @include g.mq(sm) {
            width: auto;
            display: inline-block;
            bottom: f.px2vw(48);
            right: f.px2vw(-10);
          }

          .button-link {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            transition: all .3s;

            @include g.mq(md) {
              width: 430px;
              height: 100px;
              border-radius: 50px;  
              box-shadow: 0 0 30px rgba(0,0,0,.1);
              padding: 0 20px 0 28px; 
            }

            @media screen and (min-width: 767px) and  (max-width: 1100px){
              width: f.px2vw(430,1100);
              height: f.px2vw(100,1100);
              border-radius: f.px2vw(50,1100);
            }

            @include g.mq(sm) {
              width: f.px2vw(400);
              height: f.px2vw(100);
              border-radius: f.px2vw(50);
              box-shadow: 0 0 f.px2vw(30) rgba(0,0,0,.1);
              padding: 0 f.px2vw(20) 0 f.px2vw(40); 
            }

            &:hover {
              @include g.mq(md) {
                background-color: g.$color-orange;
                color: #fff;
              }
            }

            &__txt {
              font-weight: 500;
              letter-spacing: 0;

              @include g.mq(md) {
                font-size: 20px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                font-size: f.px2vw(20,1100);
              }

              @include g.mq(sm) {
                font-size: f.px2vw(26);
                line-height: 1.45;
              }
  
              span {
  
                @include g.mq(md) {
                  font-size: 24px;
                }

                @media screen and (min-width: 767px) and  (max-width: 1100px){
                  font-size: f.px2vw(24,1100);
                }

                @include g.mq(sm) {
                  font-size: f.px2vw(30);
                }
              }
            }

            .icon {
              position: relative;
              background-color: g.$color-orange;
              border-radius: 50%;

              @include g.mq(md) {
                width: 60px;
                height: 60px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                width: f.px2vw(60,1100);
                height: f.px2vw(60,1100);
              }

              @include g.mq(sm) {
                width: f.px2vw(60);
                height: f.px2vw(60);
              }

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url("../img/common/arrow-icon01.png");
                background-size: cover;
                background-repeat: no-repeat;
  
                @include g.mq(md) {
                  width: 19px;
                  height: 12px;
                }

                @media screen and (min-width: 767px) and  (max-width: 1100px){
                  width: f.px2vw(19,1100);
                  height: f.px2vw(12,1100);
                }

                @include g.mq(sm) {
                  width: f.px2vw(34);
                  height: f.px2vw(22);
                }
              }
            }
          }
        }

        &:last-child {
          
          @include g.mq(md) {
            margin-top: 130px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(62);
            margin-left: auto;
          }

          .list-item__img {

            @include g.mq(sm) {
              margin-left: auto;
            }
          }

          .list-item__button {

            @include g.mq(md) {
              bottom: -45px;
              right: 70px;
            }

            @include g.mq(sm) {
              bottom: f.px2vw(48);
              left: f.px2vw(-10);
            }
          }
        }
      }
    }
  }
}

/*# recruit
==============================================================*/
.recruit {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;

  @include g.mq(md) {
    background-image: url("../img/top/recruit-bg01.png");
    background-position-x: center;
    height: 400px;
  }

  @media screen and (min-width: 767px) and  (max-width: 1100px){
    padding: 0 f.px2vw(20,1100);
  }

  @include g.mq(sm) {
    background-image: url("../img/top/sp/recruit-bg01.png");
    height: f.px2vw(500);
  }

  .recruit-wrap {
    max-width: 1075px;
    margin: 0 auto;
    text-align: right;

    &__message {
      display: inline-block;

      @include g.mq(md) {
        margin-top: 120px;
      }

      @include g.mq(sm) {
        margin: f.px2vw(76) f.px2vw(21) 0 0 ;
      }

      .message-txt {
        text-align: left;
        line-height: 2;
        letter-spacing: 0;

        @include g.mq(md) {
          font-size: 18px;
        }

        @include g.mq(sm) {
          font-size: f.px2vw(28);
        }
      }

      .message-button {
        text-align: right;

        @include g.mq(md) {
          margin-top: 67px;
        }

        @include g.mq(sm) {
          margin-top: f.px2vw(27);
        }

        .button-link {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          background-color: #fff;
          transition: all .3s;

          @include g.mq(md) {
            width: 280px;
            height: 100px;
            border-radius: 50px;
            box-shadow: 0 0 30px rgba(0,0,0,.1);
            padding: 0 19px 0 31px;
          }

          @include g.mq(sm) {
            width: f.px2vw(365);
            height: f.px2vw(100);
            border-radius: f.px2vw(50);
            box-shadow: 0 0 f.px2vw(30) rgba(0,0,0,.1);
            padding: 0 f.px2vw(19) 0 f.px2vw(42);
          }

          &:hover {
            @include g.mq(md) {
              background-color: g.$color-orange;
              color: #fff;
            }
          }

          &__txt {
            letter-spacing: 0;

            @include g.mq(md) {
              font-size: 24px;
            }

            @include g.mq(sm) {
              font-size: f.px2vw(30);
            }
          }

          .icon {
            position: relative;
            background-color: g.$color-orange;
            border-radius: 50%;

            @include g.mq(md) {
              width: 60px;
              height: 60px;
            }

            @include g.mq(sm) {
              width: f.px2vw(60);
              height: f.px2vw(60);
            }

            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-image: url("../img/common/arrow-icon01.png");
              background-size: cover;
              background-repeat: no-repeat;

              @include g.mq(md) {
                width: 19px;
                height: 12px;
              }

              @include g.mq(sm) {
                width: f.px2vw(34);
                height: f.px2vw(22);
              }
            }
          }
        }
      }
    }
  }
}

/*# company
==============================================================*/
.company {
  background-color: #fff3e8;

  @include g.mq(md) {
    padding: 120px 0;
  }

  @media screen and (min-width: 767px) and  (max-width: 1100px){
    padding: 120px f.px2vw(20,1100);
  }

  @include g.mq(sm) {
    padding: f.px2vw(190) f.px2vw(30);
  }

  .company-wrap {
    position: relative;
    max-width: 1080px;
    margin: 0 auto;
    background-color: #fff;

    @include g.mq(md) {
      padding: 50px 35px;
      border-radius: 4px 4px 0 0 ;
    }

    @include g.mq(sm) {
      padding: f.px2vw(70) f.px2vw(30) f.px2vw(20);
      border-radius: f.px2vw(4) f.px2vw(4) 0 0 ;
    }

    &__title {
      position: absolute;

      @include g.mq(md) {
        top: -56px;
        left: 0;
        width: 243px;
      }

      @include g.mq(sm) {
        top: f.px2vw(-74);
        left: f.px2vw(32);
        width: f.px2vw(365);
      }
    }

    &__box {

      @include g.mq(md) {
        display: flex;
      }

      .box-message {

        @include g.mq(md) {
          flex: 0 1 100%;
          margin-right: 20px;
        }

        @media screen and (min-width: 767px) and  (max-width: 1100px){
          margin-right: f.px2vw(20,1100);
        }

        &__title {
          font-weight: bold;
          border-bottom: 1px solid #e4e4e4;
          letter-spacing: .1em;

          @include g.mq(md) {
            font-size: 28px;
            padding-bottom: 20px;
          }

          @include g.mq(sm) {
            font-size: f.px2vw(40);
            padding-bottom: f.px2vw(40);
          }
        }

        &__box {

          @include g.mq(md) {
            margin: 32px 0 0 2px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(47);
          }

          .dl-item {
            display: flex;
            align-items: baseline;

            @include g.mq(md) {
              margin-top: 10px;
            }

            @include g.mq(sm) {
              margin-top: f.px2vw(14);
            }

            dt {
              font-weight: bold;
              letter-spacing: 0;

              @include g.mq(md) {
                flex: 0 0 80px;
                font-size: 14px;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                font-size: f.px2vw(14,1100);
              }

              @include g.mq(sm) {
                flex: 0 0 f.px2vw(150);
                font-size: f.px2vw(24);
              }
            }

            dd {
              font-weight: 400;
              letter-spacing: 0;

              @include g.mq(md) {
                flex: 0 1 100%;
                font-size: 18px;
                line-height: 2;
              }

              @media screen and (min-width: 767px) and  (max-width: 1100px){
                font-size: f.px2vw(18,1100);
              }

              @include g.mq(sm) {
                flex: 0 1 100%;
                font-size: f.px2vw(28);
                line-height: 1.5;
              }

              a {
                position: relative;
                color: g.$color-orange;
                border-bottom: 1px solid g.$color-orange;

                @include g.mq(md) {
                  padding-bottom: 5px;
                }

                @include g.mq(sm) {
                  padding-bottom: f.px2vw(8);
                }

                &::after {
                  content: "";
                  position: absolute;
                  background-image: url("../img/common/tab-icon01.png");
                  background-size: cover;
                  background-repeat: no-repeat;

                  @include g.mq(md) {
                    width: 13px;
                    height: 11px;
                    top: 5px;
                    right: -22px;
                  }

                  @include g.mq(sm) {
                    width: f.px2vw(18);
                    height: f.px2vw(15);
                    top: f.px2vw(8);
                    right: f.px2vw(-16);
                  }
                } 
              }

              &.dd-patern01  {
                letter-spacing: 0;
              }

              .map-txt {
                display: inline-block;

                @include g.mq(md) {
                  font-size: 12px;
                }

                @include g.mq(sm) {
                  font-size: f.px2vw(22);
                }
              }
            }

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }

      .box-map {

        @include g.mq(md) {
          flex: 0 0 580px;
          height: 518px;
          margin-top: 15px;
        }

        @media screen and (min-width: 767px) and  (max-width: 1100px){
          flex: 0 0 f.px2vw(580,1100);
          height: f.px2vw(518,1100);
        }

        @include g.mq(sm) {
          width: 100%;
          height: f.px2vw(518);
          margin-top: f.px2vw(100);
          padding: 0 f.px2vw(20);
        }

        iframe {
          
          @include g.mq(md) {
            border-radius: 4px;
          }

          @include g.mq(sm) {
            border-radius: f.px2vw(4);
          }
        }
      }
    }
  }

  .company-history {
    max-width: 1080px;
    margin: 0 auto;
    background-color: #616263;

    @include g.mq(md) {
      display: flex;
      padding: 70px 47px 60px 40px;
      border-radius: 0 0 4px 4px;
    }

    @include g.mq(sm) {
      padding: f.px2vw(68) f.px2vw(30) f.px2vw(60);
      border-radius: 0 0 f.px2vw(4) f.px2vw(4);
    }

    &__message {

      @include g.mq(md) {
        order: 1;
        flex: 0 1 100%;
      }

      .message-title {
        font-weight: bold;
        border-bottom: 1px solid #e4e4e4;
        color: #fff;
        letter-spacing: 0.1em;

        @include g.mq(md) {
          font-size: 28px;
          padding-bottom: 20px;
        }

        @include g.mq(sm) {
          font-size: f.px2vw(40);
          padding-bottom: 20px;
        }
      }

      .message-box {

        @include g.mq(md) {
          margin-top: 40px;
        }

        @include g.mq(sm) {
          margin-top: f.px2vw(38);
        }

        .dl-item {
          color: #fff;

          @include g.mq(md) {
            margin-top: 22px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(25);
          }

          dt {
            font-weight: bold;
            letter-spacing: 0;

            @include g.mq(md) {
              font-size: 14px;
            }

            @include g.mq(sm) {
              font-size: f.px2vw(24);
            }
          }

          dd {
            font-weight: 400;
            letter-spacing: 0;

            @include g.mq(md) {
              font-size: 18px;
              line-height: 2;
              margin-top: 10px;
            }

            @include g.mq(sm) {
              font-size: f.px2vw(28);
              line-height: 1.71;
              margin-top: f.px2vw(10);
            }
          }

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    &__img {

      @include g.mq(md) {
        order: 0;
        flex: 0 0 480px;
        margin-right: 37px;
      }

      @media screen and (min-width: 767px) and  (max-width: 1100px){
        flex: 0 0 f.px2vw(480,1100);
        margin-right: f.px2vw(37,1100);
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(50);
        padding: 0 f.px2vw(25);
      }
    }
  }
  .company-certification{
    padding-top: 50px;
    @include g.mq(sm) {
      padding-top: f.px2vw(60);
    }
    &__container{
      display:flex;
      column-gap:10px;
      justify-content:space-between;
      width:100%;
      max-width:1080px;
      margin:auto;
      @include g.mq(sm) {
        flex-direction:column;
        row-gap:f.px2vw(50);
        max-width:initial;
      }
    }
    &__title{
      font-size:28px;
      font-weight: 700;
      letter-spacing: 0.02em;
      @include g.mq(sm) {
        font-size:f.px2vw(40);
        letter-spacing: 0.1em;
      }
    }
    &__text{
      margin-top: 30px;
      font-size:18px;
      line-height: 2;
      letter-spacing: 0.02em;
      @include g.mq(sm) {
        margin-top: f.px2vw(30);
        font-size:f.px2vw(28);
      }
    }
    &__right{
      display:flex;
      column-gap:12px;
      @include g.mq(sm) {
        column-gap:f.px2vw(12);
      }
    }
    &__item{
      width:120px;
      max-width:100%;
      @include g.mq(sm) {
        width:f.px2vw(120);
      }
      &.sp-order-last{
        @include g.mq(sm) {
          order:2;
        }
      }
    }
  }
}

/*# food
==============================================================*/
.food {

  @include g.mq(md) {
    padding: 118px 0;
  }

  @media screen and (min-width: 767px) and  (max-width: 1100px){
    padding: 118px f.px2vw(20,1100);
  }

  @include g.mq(sm) {
    padding: f.px2vw(103) f.px2vw(30);
  }

  .food-wrap {
    position: relative;
    max-width: 1100px;
    margin: 0 auto;

    &__title {

      @include g.mq(md) {
        position: absolute;
        top: -5px;
        right: 10px;
        width: 309px;
      }

      @include g.mq(sm) {
        width: f.px2vw(451);
      }
    }

    &__box {

      @include g.mq(sm) {
        margin-top: f.px2vw(70);
      }

      .box-title {
        font-weight: bold;
        letter-spacing: 0.1em;

        @include g.mq(md) {
          font-size: 28px;
        }

        @include g.mq(sm) {
          font-size: f.px2vw(40);
        }
      }

      .box-list {

        @include g.mq(md) {
          margin-top: 74px;
          padding: 0 11px;
        }

        @include g.mq(sm) {
          margin-top: f.px2vw(100);
        }

        &__item {

          @include g.mq(md) {
            margin-top: 60px;
          }

          @include g.mq(sm) {
            padding-left: f.px2vw(45);
            margin-top: f.px2vw(85);
          }

          .item-title {
            position: relative;
            font-weight: bold;
            letter-spacing: 0.1em;

            @include g.mq(md) {
              font-size: 24px;
              padding-left: 45px;
            }

            @include g.mq(sm) {
              font-size: f.px2vw(36);
            }

            &::before {
              content: "";
              position: absolute;
              background-image: url("../img/top/food-icon01.png");
              background-size: cover;
              background-repeat: no-repeat;

              @include g.mq(md) {
                top: 0;
                left: 0;  
                width: 25px;
                height: 26px;
              }

              @include g.mq(sm) {
                top: f.px2vw(5);
                left: f.px2vw(-45);
                width: f.px2vw(25);
                height: f.px2vw(26);
              }
            }
          }

          .item-txt {
            font-weight: 400;
            line-height: 2;
            letter-spacing: 0;

            @include g.mq(md) {
              font-size: 18px;
              padding-left: 45px;
              margin-top: 32px;
            }

            @include g.mq(sm) {
              font-size: f.px2vw(28);
              padding-right: f.px2vw(5);
              margin-top: f.px2vw(42);
            }
          }

          .child-list {

            @include g.mq(md) {
              margin-top: 8px;
            }

            @include g.mq(sm) {
              margin-top: f.px2vw(25);
            }

            &__item {
              font-weight: 500;
              letter-spacing: 0;

              @include g.mq(md) {
                font-size: 22px;
                line-height: 2.63;
                padding-left: 45px;
              }

              @include g.mq(sm) {
                font-size: f.px2vw(30);
                line-height: 1.93;
                padding-left: 1.5em;
                text-indent: -1.5em;
                margin-top: f.px2vw(20);
              }

              span {

                @include g.mq(sm) {
                  padding-right: f.px2vw(25);
                }
              }

              &:first-child {
                margin-top: 0;
              }
            }
          }

          .item-box {

            @include g.mq(md) {
              display: flex;
              justify-content: space-between;  
            }

            .item-message {

              @include g.mq(md) {
                flex: 0 1 100%;
              }
            }

            .item-img {
              
              @include g.mq(md) {
                flex: 0 0 250px;
                margin-left: 150px;
              }

              @include g.mq(sm) {
                width: f.px2vw(460);
                margin: f.px2vw(50) auto 0;
              }
            }
          }

          &:first-child {
            margin-top: 0;
          }

          &:nth-child(2n) {
            .item-title {

              &::before {
                background-image: url("../img/top/food-icon02.png");
                background-size: cover;
                background-repeat: no-repeat;
              }
            }
          }
          &:last-child {
            .item-title {

              &::before {
                background-image: url("../img/top/food-icon03.png");
                background-size: cover;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
    }
  }
}

/*# contact
==============================================================*/
.contact {

  @include g.mq(md) {
    margin-top: 43px;
  }

  @media screen and (min-width: 767px) and  (max-width: 1100px){
    padding: 0 f.px2vw(20,1100);
  }

  @include g.mq(sm) {
    margin-top: 40px;
    padding: 0 30px;
  }

  .contact-wrap {
    max-width: 878px;
    margin: 0 auto;

    @include g.mq(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-weight: bold;
      letter-spacing: 0.1em;

      @include g.mq(md) {
        flex: 0 0 260px;
        padding-left: 17px;
        font-size: 30px;
      }

      @include g.mq(sm) {
        font-size: f.px2vw(40);
      }
    }

    &__message {

      @include g.mq(md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 0 1 100%;
        height: 160px;
        padding-left: 60px;
        border-left: 1px solid #e4e4e4;
      }

      @include g.mq(sm) {
        margin-top: f.px2vw(34);
      }

      .message-txt {
        font-weight: 400;
        letter-spacing: 0;

        @include g.mq(md) {
          font-size: 16px;
        }

        @include g.mq(sm) {
          font-size: f.px2vw(28);
          line-height: 2;
        }
      }

      .message-box {

        @include g.mq(md) {
          display: flex;
          margin-top: 30px;
        }

        @include g.mq(sm) {
          margin-top: f.px2vw(23);
        }

        &__item {
          display: flex;

          @include g.mq(md) {
            //20240610修正
            margin-right: 20px;
          }

          @include g.mq(sm) {
            margin-bottom: f.px2vw(40);
          }

          .item-icon {

            @include g.mq(md) {
              flex: 0 0 60px;
              margin-right: 7px;
            }

            @include g.mq(sm) {
              flex: 0 0 f.px2vw(120);
              margin-right: f.px2vw(20);
            }
          }

          .item-number {
            flex: 0 1 100%;
            width: 100%;
            word-wrap: break-word;
            font-weight: bold;
            letter-spacing: 0;
            //20240610修正
            font-size: 30px;
            @include g.mq(pad) {
              font-size: f.px2vw(18);
            }
            @include g.mq(sm) {
              font-size: f.px2vw(60);
            }

            span {
              display: block;

              @include g.mq(md) {
                font-size: 12px;
                margin: 4px 0 1px;
              }

              @include g.mq(sm) {
                font-size: f.px2vw(24);
                margin: f.px2vw(4) 0 f.px2vw(2);
              }
            }
          }

          &:last-child {
            margin-right: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}