@use "../global" as g;
@use "../foundation" as f;
/* ==========================================================================
  footer
========================================================================== */
footer {
  position: relative;
  background-color: g.$color-orange;
  z-index: 0;

  @include g.mq(md) {
    padding-top: 70px;
  }

  @media screen and (min-width: 767px) and  (max-width: 1100px){
    padding: 70px f.px2vw(20) 0;
  }

  &::before {
    content: "";
    position: absolute;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    
    @include g.mq(md) {
      background-image: url("../img/common/footer-bg01.gif");
      width: 360px;
      height: 320px;
      top: -20px;
      z-index: -1;
    }

    @include g.mq(sm) {
      background-image: url("../img/common/sp/footer-bg01.png");
      width: 100%;
      height: f.px2vw(1101);
      top: f.px2vw(-110);
    }
  }

  .footer-wrap {
    max-width: 900px;
    margin: 0 auto;
    position: relative;

    @include g.mq(md) {
      padding-bottom: 23px;
    }

    @include g.mq(sm) {
      padding: f.px2vw(160) f.px2vw(27) f.px2vw(93);
      z-index: 10;
    }

    &__box {

      @include g.mq(md) {
        display: flex;
        justify-content: space-between;  
      }

      .box-message {

        @include g.mq(md) {
          flex: 0 0 380px;
          margin-top: 10px;
        }

        @media screen and (min-width: 767px) and  (max-width: 1100px){
          flex: 0 0 f.px2vw(380,1100);
        }

        &__title {
          font-weight: bold;
          letter-spacing: 0;
          color: #fff;

          @include g.mq(md) {
            font-size: 22px;
          }

          @include g.mq(sm) {
            font-size: f.px2vw(40);
          }
        }

        &__address {
          font-weight: 400;
          line-height: 2;
          letter-spacing: 0;
          color: #fff;

          @include g.mq(md) {
            font-size: 14px;
            margin-top: 13px;
          }

          @include g.mq(sm) {
            font-size: f.px2vw(28);
            margin-top: f.px2vw(42);
          }
        }
      }

      .box-links {
        
        @include g.mq(md) {
          flex: 0 1 100%;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(47);
        }
      }

      .box-list {

        @include g.mq(md) {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;  
        }

        &__item {

          @include g.mq(md) {
            width: 46.15%;
            margin-bottom: 17px;
          }

          @include g.mq(sm) {
            margin-top: f.px2vw(40);
            text-align: center;
          }

          .item-link {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: 1px solid #fff;
            color: #fff;
            font-weight: 500;
            letter-spacing: 0;
            transition: all .3s;

            @include g.mq(md) {
              font-size: 14px;
              height: 40px;
              border-radius: 19px;  
            }

            @include g.mq(sm) {
              width: f.px2vw(480);
              height: f.px2vw(80);
              border-radius: f.px2vw(40);
              font-size: f.px2vw(28);
            }

            &:hover {
              @include g.mq(md) {
                background-color: #fff;
                color: g.$color-orange;
              }
            }
          }

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .links {
        @include g.mq(md) {
          display: flex;
          align-items: center;
        }
  
        @include g.mq(sm) {
          width: f.px2vw(480);
          margin: f.px2vw(48) auto 0;
        }

        .links-item {
          position: relative;
          text-align: center;

          &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-image: url("../img/common/arrow-icon01.png");
            background-size: cover;
            background-repeat: no-repeat;
    
            @include g.mq(md) {
              left: 0;
              width: 19px;
              height: 12px;
            }
  
            @include g.mq(sm) {
              left: f.px2vw(0);
              width: f.px2vw(38);
              height: f.px2vw(24);
            }
          }
    
          a {
            display: inline-block;
            text-align: center;
            position: relative;
            color: #fff;
            font-weight: 500;
            letter-spacing: 0;
    
            @include g.mq(md) {
              font-size: 14px;
              padding-left: 30px;
            }
    
            @include g.mq(sm) {
              font-size: f.px2vw(28);
            }
          }

          &:first-child {

            @include g.mq(md) {
              margin-right: 65px;
            }

            @include g.mq(sm) {
              margin-bottom: f.px2vw(40);
            }
          }
        }
      }
    }

  }

  .copyright {
    border-top: 1px solid #efefef;

    @include g.mq(md) {
      padding: 34px 0;
    }

    @include g.mq(sm) {
      padding: f.px2vw(55) f.px2vw(86) f.px2vw(45);
    }

    p {
      max-width: 900px;
      margin: 0 auto;
      font-weight: 400;
      letter-spacing: 0;
      color: #fff;

      @include g.mq(md) {
        font-size: 11px;
      }

      @include g.mq(sm) {
        font-size: f.px2vw(24);
      }
    }
  }
}